@import '../../scss/variables';

*[class*='CalloutTiles_container'],
*[class*='CalloutBannerFullwidth_container'] {
    + .container {
        margin-top: -48px;
    }
}

.container {
    background-position: center center;
    background-repeat: no-repeat;
    height: auto; //changed from 100%
    position: relative;
    padding: 50px 24px;

    &:before {
        content: none;
    }

    > *:first-child {
        margin-top: 0 !important; // override more specific styles
    }

    > *:last-child {
        margin-bottom: 0 !important; // override more specific styles
    }

    &[data-alignment=scale],
    &[data-alignment=center] {       
        background-position: center center;
        background-size: cover;
    }

    &[data-alignment='top-left'] {
        background-position: left top;
    }

    &[data-alignment='center-left'] {
        background-position: center left;
    }

    &[data-alignment='bottom-left'] {
        background-position: left bottom;
    }

    &[data-alignment='top-right'] {
        background-position: right top;
    }

    &[data-alignment='center-right'] {
        background-position: center right;
    }

    &[data-alignment='bottom-right'] {
        background-position: right bottom;
    }

    &[data-theme='dark'] {
        background-color: $grey;
        color: $white;
    }

    &[data-theme='light'] {
        background-color: $lightergrey;
    }

    &[data-theme='white'] {
        background-color: $white;
    }

    @each $name,
    $color in $colors {
        &[data-accentcolor="#{$name}"] {
            &::before {
                background-color: $color;
            }
        }
    }

    &[data-accentcolor='terracotta'] {
        &::before {
            background-color: $terracotta;
        }
    }

    &[data-accentcolor='ivy'] {
        &::before {
            background-color: $green;
        }
    }

    &[data-showaccentbar=true] {
        &:before {
            content: '';
            display: block;
            height: 12px;
            left: 0;
            max-width: 465px;
            position: absolute;
            top: 0;
            width: 50%;
        }
    }

    section[class*='NewsFeatured_container'] {
        background: none;
    }

    section[class*='SpotlightCarousel_containerInner'] {
        padding: 24px 0;
    }

    + section[class*='HeadlineTextBold_container__1RnDP'] {
        margin-top: 48px;
    }
}

@media screen and (max-width: $mobile) {
    .container {
        padding: 40px 24px;
    }
}